window.__tnt || (window.__tnt = {});
window.__tnt.user || (__tnt.user = {});
window.__tnt.subscription || (__tnt.subscription = {});
window.__tnt.subscription.access || (__tnt.subscription.access = {});
window.__tnt.subscription.access.previousTab || (__tnt.subscription.access.previousTab = []);

window.tntShowOfferModalTab = document.createEvent('Event');
    tntShowOfferModalTab.initEvent('tnt-show-offer-modal-tab',true,true,{});

__tnt.subscription.access.showTab = function(tab, modal, methods, noPrevious){
    var modalEl = document.getElementById(modal),
        accessMethods = document.getElementById(methods),
        activeTab = null,
        modalWrapper = null,
        modalDialog = null,
        modalDialogClass = "modal-dialog",
        tabBack = null;

    if(modalEl){
        activeTab = modalEl.querySelector('.modal-content.active');
        modalWrapper = modalEl.querySelector('.modal');
        modalDialog = modalEl.querySelector('.modal-dialog');
    }
    
    if(typeof(noPrevious) == "undefined"){ noPrevious = false }

    if(tab){
        tabBack = tab.querySelector('.tab-back');

        if(modalDialog){ modalDialog.setAttribute('class', modalDialogClass) }

        if(activeTab){
            activeTab.classList.add('hide');
            activeTab.classList.remove('active');

            if(!noPrevious){
                __tnt.subscription.access.previousTab.push(activeTab);
            }
        }

        if(tab.getAttribute('data-active-class')){ modalDialogClass = "modal-dialog " + tab.getAttribute('data-active-class'); }
        if(modalDialog){ modalDialog.setAttribute('class', modalDialogClass) }

        if(accessMethods && modalEl){
            if (accessMethods.querySelectorAll('.panel-default').length <= 2 && tab.id == 'modal-offers-tab') {
                modalEl.classList.add('modal-small');
            }else{
                modalEl.classList.remove('modal-small');
            }
        }

        // always clear history when returning to the offer tab
        if(tab.id == "modal-offers-tab"){ __tnt.subscription.access.previousTab = [] }

        // check for offer before showing purchase toggle
        if(tab.id == "user-modal-tab-login"){
            var modalPurchaseToggle = document.getElementById('user-modal-purchase-toggle');
            
            if(modalPurchaseToggle && modalPurchaseToggle.getAttribute('data-offer') != null && modalPurchaseToggle.getAttribute('data-offer') != ""){
                modalPurchaseToggle.hidden = false;
            } else if (modalPurchaseToggle){ modalPurchaseToggle.hidden = true }
        }

        // if there is a history of tabs to show 
        if(__tnt.subscription.access.previousTab.length && tabBack) {
            tabBack.classList.remove('hide');
        }else if(tabBack) {
            tabBack.classList.add('hide');
        }

        tab.classList.remove('hide');
        tab.classList.add('active');

        tntShowOfferModalTab.details = {};
        window.dispatchEvent(tntShowOfferModalTab);
    }
}

__tnt.subscription.handleCart = function (oRate, serviceId, siteName){
    if(oRate){
        var rate = sessionStorage.getItem('__tnt.service.rate'),
            ratePrice = oRate.price/100,
            rateName = oRate.name,
            rateId = oRate.id,
            serviceName = oRate.service_name;

        if(rate == null && rate != rateId){
            // rate not present or same as current 
        }else{
            var oldPrice = sessionStorage.getItem('__tnt.service.rate.price'),
                oldName = sessionStorage.getItem('__tnt.service.rate.name');

            if(window.dataLayer){
                try{
                    if(window.sessionStorage){
                        sessionStorage.removeItem('__tnt.service.name');
                        sessionStorage.removeItem('__tnt.service.rate');
                        sessionStorage.removeItem('__tnt.service.rate.name');
                        sessionStorage.removeItem('__tnt.service.rate.price');
                    }
                }catch(e){}
            }
        } 

        if (rateId != sessionStorage.getItem('__tnt.service.rate')){
            __tnt.subscription.offers.logProductAdded();

            try{
                if(window.sessionStorage){
                    sessionStorage.setItem('__tnt.service.name', serviceName);
                    sessionStorage.setItem('__tnt.service.rate', rateId);
                    sessionStorage.setItem('__tnt.service.rate.name', rateName);
                    sessionStorage.setItem('__tnt.service.rate.price', ratePrice);
                    
                }
            }catch(e){}
        }
    }
}

__tnt.subscription.setModalScrollable = function (ignoreLastState){
    var modalEl = document.getElementById('access-offers-modal'),
        modalDialogContainer = null,
        modalDialog = null,
        activeTab = null;

    if(typeof(ignoreLastState)== 'undefined'){ ignoreLastState = false; }

    if(modalEl){
        modalDialogContainer = modalEl.querySelector('.modal');
        modalDialog = modalEl.querySelector('.modal-dialog');
        activeTab = modalDialog.querySelector('.modal-content.active');
    }

    if(activeTab){
        if(activeTab.hasAttribute('data-scrollable') && !ignoreLastState){
            if(activeTab.getAttribute('data-scrollable') == "true"){
                modalDialogContainer.classList.add('modal-scrollable');
            }else{
                modalDialogContainer.classList.remove('modal-scrollable');
            }
        } else if(modalDialog.scrollHeight != 0 && modalDialog.scrollHeight > window.visualViewport.height){
            modalDialogContainer.classList.add('modal-scrollable');
            activeTab.setAttribute('data-scrollable', true);
        } else if(modalDialog.scrollHeight != 0 && modalDialog.scrollHeight < window.visualViewport.height){
            modalDialogContainer.classList.remove('modal-scrollable');
            activeTab.setAttribute('data-scrollable', false);
        }else{
            modalDialogContainer.classList.remove('modal-scrollable');
            activeTab.removeAttribute('data-scrollable');
        }
    }
}

/* Recaptcha */
__tnt.subscription.access.recaptchaIds = {};
__tnt.subscription.access.loadRecaptcha = function() {
    __tnt.loadScript('https://www.google.com/recaptcha/api.js?onload=__tntSubscriptionAccessRecaptchaOnloadCallback&render=explicit');
};
__tntSubscriptionAccessRecaptchaOnloadCallback = function() {
    var recaptchaEls = document.querySelectorAll('[data-subscription-recaptcha]');
    var recaptchaIds = __tnt.subscription.access.recaptchaIds;
    recaptchaEls.forEach(function(el) {
        recaptchaIds[el.id] = grecaptcha.render(el, {
            'sitekey': '6LdvF8kSAAAAAHtjCXotWkt5byjdinh-RPyWTH5J'
        });
    });
}

window.addEventListener('resize',function(ev){
    __tnt.subscription.setModalScrollable(true);
});

window.addEventListener('tnt-show-offer-modal-tab', function(ev){
    var ignoreScrollableCache = false;
    if(ev.detail && ev.detail.resizeTab){
        ignoreScrollableCache = true;
    }

    __tnt.subscription.setModalScrollable(ignoreScrollableCache);
});

document.addEventListener("DOMContentLoaded", function(event) {
    $('#access-offers-modal').on('shown.bs.modal', function(){
       __tnt.subscription.setModalScrollable();     
    });
});
